.kontakticons {
  position: relative;
  display: flex;
  flex-direction: row;
  font-size: 70px;
  justify-content: space-around;
  margin-bottom: 30px;
  z-index: 10;
  a {
    display: flex;
    flex-direction: column;
    justify-content: center;
    color: #ffffff;
    transition: color 0.3s;
  }
  a:hover {
    color: #bfd6dd !important;
  }
  i {
    padding: 15px 30px 10px 30px;
    width: 130px;
    transform: translateX(-50%);
    position: relative;
    left: 50%;
    svg {
      height: 70px;
    }
    img {
      height: 70px;
    }
  }
  .text {
    font-size: 16px;
    text-align: center;
  }
}

.dunkle-icons {
  a {
    color: #005a79 !important;
  }

  a:hover {
    color: #004d67 !important;
  }
}