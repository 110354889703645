.news {
  display: flex;
  flex-direction: row;
  margin-top: 50px;
  >article {
    margin-right: 50px;
  }
  article {
    width: calc(50% - 25px);
    position: relative;
    display: flex;
    flex-direction: column;
    background: #fff;
    box-shadow: 0px 0px 10px #666666;
    margin-bottom: 50px;
    padding: 20px;
    h1 {
      font-size: 24px;
      margin-top: -5px;
      margin-bottom: 10px;
      text-transform: none;
    }
    picture {
      width: 100%;
      margin-bottom: 10px;
      img {
        width: 100%;
      }
    }
  }
  article:last-child {
    margin-right: 0px;
  }
}

@include media-breakpoint-down(md) {
  .news {
    flex-direction: column;
    article {
      width: calc(100%);
    }
  }
}