@include media-breakpoint-down(lg) {
  .navbar {
    top: 0px;
    box-shadow: 0 -10px 20px 10px #000;

    .navbar-toggler {
      border: none;
      box-shadow: none;
    }

    .navbar-nav {
      height: auto;
      font-size: 25px;
      flex-direction: column;
      .nav-item {
        min-height: 35px;
        flex: unset;
        flex-direction: column;
        a {
          width: 100vw;
          text-align: center;
          justify-content: center;
        }
        .dropdown-menu {
          a {
            font-size: 20px;
          }
        }
      }
    }
    .navbar-collapse {
      position: fixed;
      top: 80px;
      width: 100vw;
    }
    .nextdate {
      display: none;
    }
  }
  body {
    .content {
      margin-top: 0px;

    }
    .teaser {
      article {
        top: 20%;
        padding-left: 20px;
        padding-right: 20px;
      }
    }
    .bilder-slider-preview {
      max-width: 100vw;
    }
  }
  footer {
    .wood {
      .holz {
        img {

        }
      }
      .footer--menu {
        flex-direction: column !important;
        display: none !important;
      }
      .logo-footer {
        width: 50%;
        left: 50%;
        position: relative;
        transform: translateX(-50%);
      }

      .socialmediaicons-footer {
        i {
          font-size: 35px;
        }
      }
    }
  }
}