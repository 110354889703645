.content--shadow-footer {
  position: absolute;
  bottom: 0px;
  width: 100vw;
  picture {
    position: absolute;
    width: 100vw;
    img {
      width: 100%;
      position: absolute;
      transform: translateY(-100%);
    }
  }
}


footer {
  z-index: 60;
  background: theme-color('primary');
  padding: 0px;
  padding-bottom: 50px;
  margin: 0px !important;
  position: relative;
  width: 100%;
  color: #fff;
  min-height: 50px;

  h2 {
    color: #ffffff;
    text-shadow: -1px -1px #003041;
  }

  hr {
    height: 0px;
    border-top: 1px solid #000;
    border-bottom: 1px solid #fff;
    opacity: 0.1;
  }
  .logo-footer {
    position: relative;
    left: 50%;
    transform: translateX(-50%);
    width: 250px;
    margin-top: 20px;
    margin-bottom: 0px;
  }

  .kontakticons {
    max-width: 500px;
    margin-left: auto;
    margin-right: auto;
  }

  a {
    color: #fff;
    transition: color 0.3s;
    font-weight: bold;
  }

  a:hover {
    color: #bfd6dd;
  }
}