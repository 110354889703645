.kontaktspalten {
  position: relative;
  display: flex;
  flex-direction: row;
  font-size: 70px;
  justify-content: space-around;
  margin-bottom: 30px;
  z-index: 10;
  .all-left {
    text-align: left;
    .kontaktlinks {
      display: flex;
      justify-content: flex-start;
      a {
        color: #fff;
      }
      i {
        right: unset !important;
        left: unset !important;
        transform: unset !important;
      }
    }
  }
  .all-right {
    text-align: right;
    .kontaktlinks {
      display: flex;
      justify-content: flex-end;
      a {
        color: #fff;
      }
      i {
        margin-right: 0px;
        right: unset !important;
        left: unset !important;
        transform: unset !important;
      }
    }
  }
  a {
    display: flex;
    flex-direction: column;
    justify-content: center;
    color: $navbar--bg;
    transition: color 0.3s;
  }
  @include media-breakpoint-down(sm) {
    .col-md-6 {
      text-align: center !important;
    }
    .kontaktlinks {
      display: flex;
      justify-content: center !important;
      margin-top: 50px;
    }
  }
  a:hover {
    color: #bfd6dd !important;
  }
  .fontawsome-icon {
    width: 130px;
    height: 130px;
    background: theme-color('primary');
    border-radius: 15px;
    margin: 15px 15px 15px 0px;
    padding: 25px;
    position: relative;
    left: 50%;
    transform: translateX(-50%);

    svg {
      max-width: 100%;
      max-height: 100%;
      vertical-align: unset !important;
      .st0 {
        fill: #ffffff;
      }
    }
    div {
      display: flex;
      justify-content: center;
      text-align: center;
    }
  }
  .kontakttext {
    font-size: 18px;
  }
}

.dunkle-icons {
  a {
    color: #005a79 !important;
  }

  a:hover {
    color: #004d67 !important;
  }
}