.iconlist {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-around;
  margin-bottom: 30px;
  .iconbtn {
    display: flex;
    width: calc(100% / 4 - 68px);
    flex-direction: column;
    justify-content: center;
    margin: 0px 34px 50px 34px;
    align-self: flex-start;
    .fontawsome-icon {
      width: 130px;
      height: 130px;
      background: theme-color('primary');
      border-radius: 15px;
      margin: 15px 15px 15px 0px;
      padding: 15px;
      position: relative;
      left: 50%;
      transform: translateX(-50%);

      svg {
        max-width: 100%;
        max-height: 100%;
        .st0 {
          fill: #ffffff;
        }
      }
    }
    div {
      display: flex;
      justify-content: center;
      text-align: center;
    }
  }
  @include media-breakpoint-down(md) {
    .iconbtn {
      width: calc(100% / 2 - 68px);
    }
  }
}

.appartment-item-list {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  .appartment-item {
    display: flex;
    flex-direction: row;
    width: calc(100% / 3);
    .icon {
      margin-right: 10px;
      a {
        display: flex;

        .fontawsome-icon {
          width: 40px;
          height: 40px;
          background: #878787;
          border-radius: 0.25rem;
          margin: 5px;
          padding: 5px;

          svg {
            * {
              stroke: #ffffff;
            }
          }
        }
      }
    }
    .description {
      p {
        margin-top: 10px;
      }
    }
  }
}